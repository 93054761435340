import gsap from 'gsap';

export function fit(contains) {
    return (parentWidth, parentHeight, childWidth, childHeight, scale = 1, offsetX = 0.5, offsetY = 0.5) => {
      const childRatio = childWidth / childHeight
      const parentRatio = parentWidth / parentHeight
      let width = parentWidth * scale
      let height = parentHeight * scale
  
      if (contains ? (childRatio > parentRatio) : (childRatio < parentRatio)) {
        height = width / childRatio
      } else {
        width = height * childRatio
      }
  
      return {
        width,
        height,
        offsetX: (parentWidth - width) * offsetX,
        offsetY: (parentHeight - height) * offsetY
      }
    }
}

export function activatePill(id) {
  const pills = document.querySelectorAll('.navigation-pill');
  Array.from(pills).forEach(pill => {
    pill.classList.remove('active');
  })
  const pill = document.querySelector(`[data-section-pill="${id}"]`);
  if(pill) {
      pill.classList.add('active')
      //history.pushState(id, name, `#${id}`);
  }
}
