import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CONSTANTS } from '@Constants/Constants';
import { activatePill } from '../../utils';

function handleAnimationMobile() {
    const sectionTl = gsap.timeline({
        scrollTrigger: {
            trigger: ".dont-speak",  
            start: "top top",
            end: "+=100%",
            scrub: 0.23,
            pin: true,
        },
    });
    
    sectionTl.fromTo(".dont-speak", 20,
                { xPercent: 0, ease: "slow" },
                { xPercent: -50, ease: "slow" }
            );
}

const handleAnimation = () => {
    const sectionTl = gsap.timeline({
        scrollTrigger: {
            trigger: ".dont-speak",  
            start: "top top",
            end: "+=150%",
            scrub: 0.23,
            pin: true,
        },
    });

    sectionTl.fromTo(".dont-speak_heading", {y: '60px', opacity: 0}, {opacity: 1, y: '0px'})
    .fromTo(".dont-speak_paragraph-1", {opacity: 0}, {opacity: 1, delay: 1, duration: 1})
    .fromTo(".delLine-1", { scaleX: 0 }, {scaleX: 1, delay: 1, duration: 1})
    .fromTo(".dont-speak_paragraph-2", {opacity: 0}, {opacity: 1, delay: 1, duration: 1})
    .fromTo(".delLine-2", { scaleX: 0 }, {scaleX: 1, delay: 1, duration: 1})
    .fromTo(".dont-speak_paragraph-3", {opacity: 0}, {opacity: 1, delay: 1, duration: 1})
    .fromTo(".delLine-3", { scaleX: 0 }, {scaleX: 1, delay: 1, duration: 1});
}

export default function DontSpeak() {
    
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.matchMedia({
            "(max-width: 1024px)": function() {
                handleAnimationMobile();
            },
            "(min-width: 1025px)": function() {
                handleAnimation();
            }
        });
        
        gsap.timeline({
            scrollTrigger: {
                trigger: '.businessObserver4',
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.business.id)
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.business.id)
                }
            }
        });
    }, [])

    return (
        <>
            <div className="fullscreen dont-speak observe businessObserver4" data-section-type={CONSTANTS.sectionType.dark}>
                <img className="dont-speak_bg"
                    src="/images/nie_mow_mi/nie_mow_mi@0.5x.jpg"
                    srcSet="/images/nie_mow_mi/nie_mow_mi@0.5x.jpg 540w, /images/nie_mow_mi/nie_mow_mi@1x.jpg 1920w, /images/nie_mow_mi/nie_mow_mi@2x.jpg 2000w"
                    sizes="(max-width: 540px) 540px, (max-width: 1920px) 1920px, 100vw"
                    loading="lazy"
                />
                <div className="container horizontal">
                    <div className="text-white dont-speak_content scrollable-content">
                        <div>
                            <h2 className="dont-speak_heading">Nie mów mi, <span>jak mam tankować</span></h2>
                        </div>
                        <div className="dont-speak_second">
                            <p className="dont-speak_paragraph dont-speak_paragraph-1">Elektromobilność to <span><span className="delLine delLine-1"></span>przyszłość</span> teraźniejszość.</p>
                            <p className="dont-speak_paragraph dont-speak_paragraph-2">Elektromobilność się <span><span className="delLine delLine-2"></span>nie</span> opłaca!</p>
                            <p className="dont-speak_paragraph dont-speak_paragraph-3">Elektromobilność <span><span className="delLine delLine-3"></span>nie</span> jest ekologiczna.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}